<template>
  <MainForm v-on:submit="submit" />
</template>

<script>
import MainForm from './form'
export default {
  name: 'postCreate',
  created() {
    this.$store.dispatch('tag/FETCH')
  },
  methods: {
    submit() {
      const vm = this
      this.$nprogress.start()
      vm.$store.dispatch('post/STORE').then((res) => {
        vm.$router.push({ path: `/posts/edit/${res.data.data.id}` })
      }).catch(err => {
        this.$notification.error({
          message: 'Create post error',
          description: err.message,
        })
        this.$nprogress.done()
      })
    },
  },
  components: {
    MainForm,
  },
}
</script>
